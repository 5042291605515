




import {Vue, Component, Prop} from 'vue-property-decorator';
import AuthLoginForm from '@/app/modules/auth/components/AuthLoginForm.vue';

@Component({
  components: {
    AuthLoginForm,
  },
})
export default class AuthLoginView extends Vue {
}
